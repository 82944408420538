<!--
 * @Description:
 * @Autor: Zhongyu
 * @Date: 2021-09-01 15:29:17
 * @LastEditors: Lizy
 * @LastEditTime: 2021-12-09 15:24:26
-->
<template>
  <div class="customerCase">
    <!-- 客户案例页版心 -->
    <div class="product">
      <div class="product_img" v-for="(item, index) in banner" :key="index">
        <div class="banner_img">
            <img
              :src="$utils.picturePrefix(item.image)"
              alt=""
            />
        </div>
        
      </div>
    </div>
    <div class="content">
      <div class="customerCase_left">
        <div v-if="list.length > 0" class="godds_list">
          <div
            class="item"
            v-for="(item, index) in list"
            :key="index"
            @click="go_detail(item.id)"
          >
            <div class="godds_list_img">
              <img :src="$utils.picturePrefix(item.image)" />
            </div>
            <div class="godds_list_text">
              <div class="caption text-ellipsis" :title="item.title">
                {{ item.title }}
              </div>
              <div class="article">{{ item.introduction }}</div>
              <div class="entrance">
                <span class="iconfont icon-jiantou_xiangyou"></span>
                <span class="details">查看详情</span>
              </div>
            </div>
          </div>
        </div>
        <empty
              v-else
              description="暂无案例"
              style="width: 100%"
            ></empty>
      </div>
      <Pagination :total="total" @change="pagin_change" v-if="total != 0"></Pagination>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import Empty from "@/components/Empty";
export default {
  components: {
    Pagination,
    Empty
  },
  data() {
    return {
      // 背景
      banner: [],
      //  客户案列页展示数据
      list: [],
      num: 10, //页数
      page: 1,
      total: 0
    };
  },
  computed: {},
  watch: {},
  created() {
    this.query_getlist();
    this.query_banner();
  },
  methods: {
    bannerHeight() {
        let width = window.innerWidth;
        let getClass = document.getElementsByClassName("banner_img")[0];
        // eslint-disable-next-line no-self-assign
        width < 1200 ? (width = 1200) : (width = width);
        let height = width / 1920 * 360;
        console.log(getClass,height);
        return getClass.style.height = height + "px";
    },
    //  @click 点击后跳转至客户详情页
    go_detail(id) {
      // this.$router.push(`/customer-case/detail?id=${id}`);
      let routeData = this.$router.resolve({
        path: `/customer-case/detail?id=${id}`,
      });
      window.open(routeData.href, "_blank");
      // window.open(`/water-info/customer-case/detail?id=${id}`);
    },
    // 客户案列
    query_getlist() {
      this.$api
        .getCustomerCase({
          num: this.num,
          page: this.page
        })
        .then(res => {
          let {
            code,
            data: { records, total }
          } = res;

          if (code == 200) {
            this.total = +total;
            this.list = records;
            // this.list.forEach(e => {
            //   // this.$utils.picturePrefix(e.image);
            //   e.image = this.$utils.picturePrefix(e.image);
            //   //   "http://49.234.124.108:8080/water_purification" + ;
            // });
          }
        });
    },
    //  背景图
    query_banner() {
      this.$api.getBanner({ type: 3 }).then(res => {
        let { code, data } = res;
        if (code == 200) {
          this.banner = data;
          setTimeout(() => {
            this.bannerHeight();
          }, 0);
        }
      });
    },
    // 分页方法
    pagin_change(page, pageSize) {
      this.page = page;
      this.num = pageSize;
      this.query_getlist();
    }
  }
};
</script>
<style lang="less" scoped>
@import "./index.less";
</style>
